import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { Link } from "gatsby";
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import ImageTransform from "./common/ggfx-client/module/components/image-transform";
import { VideoPlayButton } from '../components/icon';

function ImageSlider(props) {
    const [showVideo, setShowVideo] = React.useState(false);
    const [videoindex, setVideoindex] = React.useState(false);
    const [, setPlay] = React.useState(false);
    const playVideo = (video_index) => {
        setVideoindex(video_index)
        setShowVideo(true)
    }
    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': event,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }
    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerPadding: '192px',
                }
            },
            {
                breakpoint: 1293,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerPadding: '140px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '0px',
                    autoplay: true,
                }
            }
        ]
    };

    return (
        <div className="news-slider">
            <Slider {...settings}>
                {props.data?.edges && props.data.edges.map((item, index) => {
                    if (item.node.Select_Page == props.pagetitle || props.newsvideos) {
                        var videoid = getVideoId(item.node.Embed_Video_URL);
                        let processedImages = JSON.stringify({});
                        if (item.node?.imagetransforms?.Video_Tile_Transforms) {
                            processedImages = item.node.imagetransforms.Video_Tile_Transforms;
                        }
                        const image_url = item.node.Video_Tile && item.node.Video_Tile.internal.description ? item.node.Video_Tile.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';

                        return <>
                            <div className="news-block text-center">
                                <figure className="news-img">
                                    {item.node.Video_Tile &&
                                        <Link to={`/about-us/our-videos/${item.node.URL}/`}>
                                           <div className='bwimg'>
                                             <ImageTransform imagesources={image_url} renderer="srcSet" imagename="our-videos.Video_Tile.sliderimg" attr={{ alt: item.node.Title + ' - Orlando Reid' }} imagetransformresult={processedImages} id={item.node.strapiId} />
                                            </div>
                                        </Link>

                                    }
                                    {item.node.Embed_Video_URL &&
                                        <button className="btn-play"
                                            onClick={(e) => { playVideo(index, videoid.id, e) }}>
                                            <VideoPlayButton />
                                        </button>
                                    }
                                    {showVideo && videoindex == index &&
                                        <YouTube
                                            video={videoid.id}
                                            autoplay
                                            onEnd={() => { setPlay(false); setShowVideo(false) }}
                                            modestBranding={1}
                                            onPlaying={trackerVideo(item.node.Title)}

                                        />
                                    }
                                </figure>
                                <div className="news-title">
                                    <Link to={`/about-us/our-videos/${item.node.URL}/`}>
                                        <h3>{item.node.Title}</h3>
                                    </Link>
                                </div>
                            </div>
                        </>
                    }
                })}


            </Slider >
        </div>


    )

}

export default ImageSlider;