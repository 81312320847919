import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby"
import { Breadcrumb, Form } from 'react-bootstrap';

/**
 * Assets
 */


const Breadcrumbs = (props) => {

if (typeof window !== "undefined") {
  var pageURL = window.location.href.split("#")[0] ? window.location.href.split("#")[0] : '';
  var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
  var lastURLSegment = removeSlash.substr(removeSlash.lastIndexOf('/') + 1);  
}
if(props.alias != null){
  var lastURLSegment = props.alias
}

 const data = useStaticQuery(graphql`
    query BreadcrumbQuery {  
      site {
        siteMetadata {
          title
        }
      }
      
    allStrapiAllMenus {
      edges {
        node {
          id
          Label
          Show_In_Burger_Menu
          URL
          main_parent {
            URL
            Show_In_Burger_Menu
            Label
          }
          sub_parent {
            URL
            Label
          }
        }
      }
    }    
    }
  `)

    return (
        <div className="breadcrumbs">
			<Breadcrumb>
				<Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
				{data.allStrapiAllMenus.edges.map(({node}, key) => {
				return <>
				{node.main_parent != null && node.URL == lastURLSegment &&
				<Breadcrumb.Item><Link to={`/${node.main_parent.URL}/`}>{node.main_parent.Label}</Link></Breadcrumb.Item>
				}
				{node.main_parent != null && node.sub_parent != null && node.URL == lastURLSegment &&
				<Breadcrumb.Item><Link to={`/${node.main_parent.URL}/${node.sub_parent.URL}/`}>{node.sub_parent.Label}</Link></Breadcrumb.Item>
				}
				{node.main_parent != null && node.URL == lastURLSegment && props.alias ==null &&
				<Breadcrumb.Item><Link to={`/${node.main_parent.URL}/`} >{node.Label}</Link></Breadcrumb.Item>
				}
        {node.main_parent != null &&  node.sub_parent == null && node.URL == lastURLSegment && props.alias !=null && 
        <Breadcrumb.Item><Link to={`/${node.main_parent.URL}/${node.URL}/`} >{node.Label}</Link></Breadcrumb.Item>
        }
        {node.main_parent != null && node.sub_parent != null &&  node.URL == lastURLSegment && props.alias !=null && 
        <Breadcrumb.Item><Link to={`/${node.main_parent.URL}/${node.sub_parent.URL}/${node.URL}/`} >{node.Label}</Link></Breadcrumb.Item>
        }
        {node.main_parent == null && node.sub_parent == null && node.URL == lastURLSegment && 
        <Breadcrumb.Item><Link>{node.Label}</Link></Breadcrumb.Item>
        }
				</>
				})}
        {props.detailname !=null && props.alias !=null &&
        <Breadcrumb.Item>{props.detailname}</Breadcrumb.Item> 
        }
				
			</Breadcrumb>
        </div>
    )
}

export default Breadcrumbs;
