import React, {useEffect} from "react";
import { Container, Form } from 'react-bootstrap';
import Header2 from "../components/Header2/header2";
import Footer from "../components/Footer/footer";
import NewsletterForm from "../components/forms/newsletter-form";
import RelatedVideos from "../components/other-videos"
import YouTube from '@u-wave/react-youtube';
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import ContactSection from '../components/SectionContact/section-contact'
import getVideoId from 'get-video-id';
import Subscribe from "../images/subscribe.png";
import Breadcrumbs from "../components/breadcrumbs";
import ReactMarkdown from "react-markdown/with-html";
import { VideoPlayButton } from '../components/icon';
import SEO from "../components/Seo/seo";
import { Helmet } from "react-helmet"
import $ from "jquery"
export default ({data}) => {
  const Page = data.strapiOurVideos
  const GlobalConfig = data.strapiGlobalConfig
  const Othervideos = data.allStrapiOurVideos
  const [showVideo, setShowVideo] = React.useState(false);
  const [isPlay, setPlay] = React.useState(false);
  const  videoid = getVideoId(Page.Embed_Video_URL);
  const trackerVideo = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
}
useEffect(() => {
  $('.sec-video').appendTo('.videoshow')
},[]);

  let processedImages = JSON.stringify({});
  if (Page?.imagetransforms?.Video_Banner_Transforms) {
      processedImages = Page.imagetransforms.Video_Banner_Transforms;
  }
  const image_url = Page.Video_Banner && Page.Video_Banner.internal.description ? Page.Video_Banner.internal.description.replace("File ","").replace('"','').replace('"','') : '';  
  const metadesc = "Look into "+Page.Title+" here, subscribe with Orlando Reid and stay updated.";
  //console.log("Embed_Video_URL",Page.Embed_Video_URL)
  return (
    <>
    <SEO title={Page.Title} description={metadesc} />
 <Helmet>
          <script type="application/ld+json">{`
             {
   "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "${Page.Title} | Orlando Reid",
  "description": "${metadesc}",
  "thumbnailUrl": "${image_url}",
  "uploadDate": "2021-06-09",
  "duration": "PT01M17S",
 "contentUrl": "${Page.Embed_Video_URL}",
  "embedUrl": "https://www.youtube.com/embed/${videoid.id}"
             }
          `}</script>
        </Helmet>

    <div className="wrapper video-detail">
    <Header2 />
<div className="news-content-video">
        <Container>
    <div className='about-text landing-intro col-lg-9'>      
        <Container>
          <div className="news-head text-center"  >
          <Breadcrumbs alias="our-videos" detailname = {Page.Title}/>
            <h1>{Page.Title}</h1>
            <ReactMarkdown source={Page.Description} escapeHtml={false}/>            
          </div>          
        </Container>
      </div>
            {/* section video start */}
      <section className="sec-video d-md-block banner">
        <div className="video-play">
            {

            showVideo &&

            <YouTube
            video={videoid.id}
            autoplay
            showRelatedVideos={0}
            onEnd={e => {setPlay(false);setShowVideo(false)}}
            modestBranding={1}
            onPlaying={trackerVideo(Page.Title)}

            />

            }

          </div>
        <Container className="video-container">
          <div className="vedio-card">
            <ImageTransform imagesources={image_url} renderer="srcSet" imagename="our-videos.Video_Banner.bannerimg"attr={{ alt: Page.Title+ ' - Orlando Reid' }} imagetransformresult={processedImages} id={Page.strapiId}/>

            <button className="btn-play"
                  onClick = { e => {setPlay(true);setShowVideo(true)}}
            >
              <VideoPlayButton />
            </button>
          </div>
        </Container>
      </section>
          <Form className="newsletter-block banner-newsletter">
            
            <NewsletterForm />

            <div className="subscribe text-center d-md-flex align-items-md-center justify-content-md-center animate__animated">
              <img src={Subscribe} alt="img" />
              <span>
              <strong>Stay up to date </strong>
              with our new videos by
              <a rel="noopener noreferrer" href="https://www.youtube.com/channel/UCR0ob8Yam7B9tuG-81So3GA" target='_blank'> subscribing to our YouTube</a>
              </span>
            </div>
          </Form>
          <section className="sec-team-video">
          <h2>Other News, Insights and Video from Orlando Reid</h2>
          <RelatedVideos data={Othervideos} />
        </section>
        </Container>

    </div>
    <ContactSection getInTouch = {GlobalConfig}  />

    <Footer footerDetails = {GlobalConfig} />

    </div>
    </>
  )
}

export const pageQuery = graphql`
  query VideoQuery($slug: String!) {
      strapiOurVideos(URL: {eq: $slug}) {
        strapiId
        Title 
        Description
        Embed_Video_URL
        Video_Banner {
          publicURL
          internal {
            description
          }
        }
        imagetransforms {
          Video_Banner_Transforms
        }
      }
      allStrapiOurVideos {
        edges {
          node {
            strapiId
            Title
            URL
            Embed_Video_URL
            Video_Tile {
              publicURL
              internal {
                description
              }
            }
            imagetransforms {
              Video_Tile_Transforms
            }
          }
        }
      }
      strapiGlobalConfig {
          Get_In_Touch_Content
          Get_In_Touch_Embed_Video_Link
          Footer_Office_Address
          Footer_Newsletter_Title
          Instagram_Link
          Linkedin_Link
          Twitter_Link
          Facebook_Link
          Get_In_Touch_Image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 900 ,maxHeight: 600) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          Team_Box_Content
          Team_Box_Video_URL
          Team_Box_Image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 900 ,maxHeight: 600) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          Clients {
            Client
            Client_Image {
              publicURL
            }
            Client_Url
          }
          Footer_Links {
            label
            Menu_item {
              URL
              Secondary_URL
            }
          }
        }
    }
`
